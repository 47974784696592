import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { ActionOrEventTypeHandlers, FormConnections } from 'comp/screenBuilder/types/eventsTypes';
import type { TitleOrLabel } from 'comp/screenBuilder/types/screenConfigTypes';
import type {
  FormConfig,
  FormAction,
  FormListCreateCallback,
  FormListDeleteCallback,
} from 'comp/screenBuilder/comp/formBuilder';
import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import nestedProperty from 'nested-property';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InnerContext } from 'comp/screenBuilder/context';
import { defaultAction, actionTypes } from 'comp/screenBuilder/comp/formBuilder';
import clearObjectValues from 'utils/object/clearObjectValues';
import type { SelectValue } from 'antd/lib/select/index';
import ListDropdown from './comp/listDropdown';
import getInitialActiveAction from '../../utils/getInitialActiveAction';
import getNameListForForm from '../../utils/getNameListForForm';
import ActionButtons from '../../comp/actionButtons';
import Actions from '../../comp/actions';
import FormSection from '../../comp/formSection';
import type { ListDropdownType } from './comp/listDropdown';

type FormListProps<T> = {
  form: FormInstance;
  formConfig: FormConfig;
  formLocalValues: React.MutableRefObject<Record<string, unknown>>;
  formListName: (string | number)[];
  listDropdown: ListDropdownType<T>;
  handlers: ActionOrEventTypeHandlers;
  formConnections?: FormConnections;
  title?: TitleOrLabel;
  actions?: FormAction[];
  initialActiveActionType?: string;
  updateFormLocalValues: () => void;
};

function FormList<T>({
  form,
  formConfig,
  formLocalValues,
  formListName,
  listDropdown,
  handlers,
  formConnections,
  title,
  actions,
  initialActiveActionType,
  updateFormLocalValues,
}: FormListProps<T>): JSX.Element {
  const { sections } = formConfig;
  const { updateInnerRefProperty, updateFragmentProperty } = useContext(InnerContext);
  const [activeAction, setActiveAction] = useState<FormAction>(
    getInitialActiveAction(actions, initialActiveActionType)
  );
  const listDropdownIsEmpty = listDropdown.getFilteredOptions().length === 0;

  function onClickDispatch(value: SelectValue | undefined) {
    if (value !== undefined) {
      if (formConnections && formConnections.dispatch) {
        formConnections.dispatch.forEach((item) => document.dispatchEvent(new CustomEvent(item, { detail: value })));
      }
    }
  }
  function onClickCreateAction(add: FormListCreateCallback) {
    if (formLocalValues.current) {
      // Get example object from data, clear all properties values and add it to the list.
      const formListNameInitial = formListName.map((path) => (typeof path === 'number' ? 0 : path));
      const valuesFromInitialData = nestedProperty.get(formLocalValues.current, formListNameInitial.join('.')) as T[];
      const newInitialValue =
        valuesFromInitialData && valuesFromInitialData.length > 0
          ? clearObjectValues(valuesFromInitialData[0])
          : ({} as T);

      // Add the new item to the form list.
      add(newInitialValue);
      // Add the new option to the list dropdown and select it.
      listDropdown.createAndSelectOption(newInitialValue);

      if (formConnections && formConnections.dispatch) {
        formConnections.dispatch.forEach((item) =>
          document.dispatchEvent(new CustomEvent(item, { detail: newInitialValue }))
        );
      }
    }
  }

  function onClickDeleteAction(remove: FormListDeleteCallback) {
    const selectedOptionValue = listDropdown.getSelectedOptionValue();

    if (selectedOptionValue !== undefined) {
      // Remove the option from the Form.List component.
      remove(selectedOptionValue);
      // Remove the option from the ListDropdown component.
      listDropdown.deleteOptionAtIndex(selectedOptionValue);
    }

    // Reset to preview mode.
    setActiveAction(defaultAction);
    updateFragmentProperty(formConfig.key, 'activeActionType', defaultAction.type);
    // Delete action currently doesn't have confirm and dismiss buttons that's why we save the value here.
    updateFormLocalValues();
    updateInnerRefProperty('formsTouched', true);
  }

  function onClickAction(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    onClickCreateCallback?: FormListCreateCallback,
    onClickDeleteCallback?: FormListDeleteCallback
  ) {
    if (!actions) {
      return;
    }

    const { actionType } = event.currentTarget.dataset;

    if (actionType) {
      const action = actions.find(({ type }) => type === actionType);
      if (action) {
        // Execute handlers defined in the configuration file.
        if (handlers && handlers[actionType]) {
          const { self } = handlers[actionType];

          if (self !== undefined) {
            self(form.getFieldsValue(true), listDropdown.getSelectedOption());
          }
        }

        setActiveAction(action);
        updateFragmentProperty(formConfig.key, 'activeActionType', action.type);

        if (actionType === actionTypes.CREATE && onClickCreateCallback) {
          onClickCreateAction(onClickCreateCallback);
        } else if (actionType === actionTypes.DELETE && onClickDeleteCallback) {
          onClickDeleteAction(onClickDeleteCallback);
        }
      }
    }
  }

  async function onClickActionButton(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    lastFieldIndex?: number,
    onClickDismissCallback?: FormListDeleteCallback
  ) {
    const { actionType, buttonType } = event.currentTarget.dataset;

    if (actionType && buttonType) {
      // Execute handlers defined in the configuration file.
      if (handlers && handlers[actionType] && handlers[actionType][buttonType]) {
        handlers[actionType][buttonType](form.getFieldsValue(true));
      }

      if (actionType === actionTypes.CREATE) {
        if (buttonType === 'dismiss') {
          // On DISMISS changes from CREATE action.
          // Remove last item in list using the Form.List remove method
          // and remove the option from the listDropdown.
          if (lastFieldIndex !== undefined && onClickDismissCallback) {
            onClickDismissCallback(lastFieldIndex);
            listDropdown.deleteOptionAtIndex(lastFieldIndex);
          }
        }

        if (buttonType === 'confirm') {
          // On CONFIRM changes from CREATE action.
          // If fields validation fails stop the execution of this function.
          // We don't want to set the default action as active action.
          try {
            const selectedOptionValue = listDropdown.getSelectedOptionValue();
            const listIndex = selectedOptionValue !== undefined ? [...formListName, selectedOptionValue] : undefined;
            const nameList = getNameListForForm(formConfig, listIndex);
            await form.validateFields(nameList);
            updateFormLocalValues();
            updateInnerRefProperty('formsTouched', true);
          } catch (error) {
            return;
          }
        }
      }

      if (actionType === actionTypes.UPDATE) {
        if (buttonType === 'dismiss') {
          // On DISMISS changes from UPDATE action.
          // Reset the fields values to what we have in local state.
          form.setFieldsValue(formLocalValues.current);
        }

        if (buttonType === 'confirm') {
          // On CONFIRM changes from UPDATE action.
          // If fields validation fails stop the execution of this function.
          // We don't want to set the default action as active action.
          try {
            const selectedOptionValue = listDropdown.getSelectedOptionValue();
            const listIndex = selectedOptionValue !== undefined ? [...formListName, selectedOptionValue] : undefined;
            const nameList = getNameListForForm(formConfig, listIndex);
            await form.validateFields(nameList);
            updateFormLocalValues();
            updateInnerRefProperty('formsTouched', true);
          } catch (error) {
            return;
          }
        }
      }
    }

    setActiveAction(defaultAction);
    updateFragmentProperty(formConfig.key, 'activeActionType', defaultAction.type);
  }

  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => {
        return (
          <Root>
            <Header>
              <GroupLeft>
                {title && (
                  <Title>
                    <FormattedMessage id={`formBuilder.${title.id}.${activeAction.type}`} />
                  </Title>
                )}
                <ListDropdown
                  listDropdown={listDropdown}
                  activeAction={activeAction}
                  onClickCreateCallback={add}
                  onClickDeleteCallback={remove}
                  onClickDispatch={onClickDispatch}
                />
              </GroupLeft>
              <Actions
                actions={actions}
                activeAction={activeAction}
                listDropdownIsEmpty={listDropdownIsEmpty}
                onClickAction={onClickAction}
                onClickCreateCallback={add}
                onClickDeleteCallback={remove}
              />
              <ActionButtons
                action={activeAction}
                lastFieldIndex={fields.length - 1}
                onClickActionButton={onClickActionButton}
                onClickDismissCallback={remove}
              />
            </Header>
            {fields.map((field, index) => {
              if (index !== listDropdown.getSelectedOptionValue()) {
                return null;
              }

              return (
                <div key={`list-item-${field.fieldKey}`}>
                  {sections.map((section) => (
                    <FormSection
                      key={section.key}
                      section={section}
                      formListField={field}
                      activeAction={activeAction}
                    />
                  ))}
                </div>
              );
            })}
          </Root>
        );
      }}
    </Form.List>
  );
}

export default FormList;

const Root = styled.div`
  margin: 16px 0;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.darker[0]};
`;

const GroupLeft = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const Title = styled.h2`
  width: 100%;
  margin-bottom: 0;
  margin-right: 16px;
`;
