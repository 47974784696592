import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { ColumnsType } from 'antd/lib/table';
import type { ActionOrEventTypeHandlers, FormConnections } from 'comp/screenBuilder/types/eventsTypes';
import type { TitleOrLabel, FormBuilderProps } from 'comp/screenBuilder/types/screenConfigTypes';
import type {
  FormConfig,
  FormAction,
  FormTableDataItem,
  FormListCreateCallback,
  FormListDeleteCallback,
} from 'comp/screenBuilder/comp/formBuilder';
import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import nestedProperty from 'nested-property';
import { Form, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InnerContext, OuterContext } from 'comp/screenBuilder/context';
import CollapsibleCard from 'comp/common/collapsibleCard/CollapsibleCard';
import clearObjectValues from 'utils/object/clearObjectValues';
import { defaultAction, actionTypes } from 'comp/screenBuilder/comp/formBuilder';
import EntryAction from './comp/entryAction';
import ListOperationsEvents from './comp/listOperationsEvents';
import ActionButtons from '../../comp/actionButtons';
import Actions from '../../comp/actions';
import { FormField } from '../../comp/formSection';
import getInitialActiveAction from '../../utils/getInitialActiveAction';
import getAllFieldsFromForm from '../../utils/getAllFieldsFromForm';
import getNameListForForm from '../../utils/getNameListForForm';

type FormTableProps<T> = {
  form: FormInstance;
  formConfig: FormConfig;
  formLocalValues: React.MutableRefObject<Record<string, unknown>>;
  formListName: (string | number)[];
  filteredTableData: FormTableDataItem<T>[];
  filteredIndexes: number[];
  screenFormConfigKey: string;
  uniqueIdProp: (string | number)[];
  formBuilderProps?: FormBuilderProps;
  handlers: ActionOrEventTypeHandlers;
  formConnections?: FormConnections;
  title?: TitleOrLabel;
  actions?: FormAction[];
  itemActions?: FormAction[];
  initialActiveActionType?: string;
  updateFormLocalValues: () => void;
  addTableRow: (detail: T) => void;
  removeLastTableRow: () => void;
};

function FormTable<T>({
  form,
  formConfig,
  formLocalValues,
  formListName,
  filteredTableData,
  filteredIndexes,
  screenFormConfigKey,
  uniqueIdProp,
  formBuilderProps = {},
  handlers,
  formConnections,
  title,
  actions,
  itemActions,
  initialActiveActionType,
  updateFormLocalValues,
  addTableRow,
  removeLastTableRow,
}: FormTableProps<T>): JSX.Element | null {
  const { tableListOperationsEvents, renderEvents, ...restHandlers } = handlers;
  const { actionsDisabled, callbacks } = useContext(OuterContext);
  const { getInnerRef, updateInnerRefProperty, updateFragmentProperty } = useContext(InnerContext);
  const [activeAction, setActiveAction] = useState<FormAction>(
    getInitialActiveAction(actions, initialActiveActionType)
  );
  const header = title ? <FormattedMessage id={`formBuilder.${title.id}.${activeAction.type}`} /> : '';
  const [current, setCurrentPage] = useState(1);
  const pageSize = formBuilderProps.pageSize ?? 5;
  const expandable = renderEvents
    ? {
        expandedRowRender: renderEvents.expandedRowRender,
        rowExpandable: () => true,
      }
    : undefined;

  function handleOnChangeCurrentPage(page: number) {
    setCurrentPage(page);
  }

  function onClickCreateAction(add: FormListCreateCallback) {
    if (formLocalValues.current) {
      // Get example object from data, clear all properties values and add it to the list.
      const formListNameInitial = formListName.map((path) => (typeof path === 'number' ? 0 : path));
      const valuesFromInitialData = nestedProperty.get(formLocalValues.current, formListNameInitial.join('.')) as T[];
      const newInitialValue =
        valuesFromInitialData && valuesFromInitialData.length > 0
          ? clearObjectValues(valuesFromInitialData[0])
          : ({} as T);

      add(newInitialValue);
      addTableRow(newInitialValue);

      if (formConnections && formConnections.dispatch) {
        formConnections.dispatch.forEach((item) =>
          document.dispatchEvent(new CustomEvent(item, { detail: newInitialValue }))
        );
      }
    }
  }

  function onClickAction(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    onClickCreateCallback?: FormListCreateCallback,
    onClickDeleteCallback?: FormListDeleteCallback
  ) {
    if (!actions) {
      return;
    }

    const { actionType } = event.currentTarget.dataset;

    if (actionType) {
      const action = actions.find(({ type }) => type === actionType);

      if (action) {
        // Execute handlers defined in the configuration file.
        if (restHandlers && restHandlers[actionType]) {
          const { self } = restHandlers[actionType];

          if (self !== undefined) {
            self(form.getFieldsValue(true));
          }
        }

        setActiveAction(action);
        updateFragmentProperty(formConfig.key, 'activeActionType', action.type);

        if (actionType === actionTypes.CREATE && onClickCreateCallback) {
          onClickCreateAction(onClickCreateCallback);
        } else if (actionType === actionTypes.DELETE && onClickDeleteCallback) {
          console.error('ThrowNotImplementedException');
        }
      }
    }
  }

  function onClickItemAction(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (!itemActions) {
      return;
    }

    const { actionType, itemId, entryIndex } = event.currentTarget.dataset;

    if (actionType) {
      const action = itemActions.find(({ type }) => type === actionType);

      if (action) {
        // Execute handlers defined in the configuration file.
        if (restHandlers && restHandlers[actionType]) {
          const { self } = restHandlers[actionType];

          if (self !== undefined) {
            let recordItem;

            if (itemId) {
              recordItem = filteredTableData.find((tableRowData) => {
                const rightSideCompare = !Number.isNaN(Number(itemId)) ? parseInt(itemId, 10) : itemId;
                return (nestedProperty.get(tableRowData.item, uniqueIdProp.join('.')) as string) === rightSideCompare;
              });
            } else if (entryIndex !== undefined) {
              recordItem = filteredTableData.find((tableRowData) => {
                const rightSideCompare = !Number.isNaN(Number(entryIndex)) ? parseInt(entryIndex, 10) : entryIndex;
                return tableRowData.index === rightSideCompare;
              });
            }

            self(form.getFieldsValue(true), recordItem);
          }
        }

        // ItemAction is the same as Action and ActionButton combined.
        updateInnerRefProperty('formsTouched', true);
        if (typeof callbacks?.onClickActionButton === 'function' && typeof getInnerRef === 'function') {
          callbacks.onClickActionButton(getInnerRef());
        }
      }
    }
  }

  async function onClickActionButton(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    lastFieldIndex?: number,
    onClickDismissCallback?: FormListDeleteCallback
  ) {
    const { actionType, buttonType } = event.currentTarget.dataset;

    if (actionType && buttonType) {
      if (actionType === actionTypes.CREATE) {
        if (buttonType === 'dismiss') {
          // On DISMISS changes from CREATE action.
          // Remove last item in list using the Form.List remove method.
          if (lastFieldIndex !== undefined && onClickDismissCallback) {
            onClickDismissCallback(lastFieldIndex);
            removeLastTableRow();
          }
        }

        if (buttonType === 'confirm') {
          // On CONFIRM changes from CREATE action.
          // If fields validation fails stop the execution of this function.
          // We don't want to set the default action as active action.
          try {
            const nameLists = filteredTableData
              .map((item, index) => {
                const listIndex = filteredIndexes[index + (current - 1) * pageSize];
                const nameList = getNameListForForm(formConfig, [...formListName, listIndex]);
                return nameList;
              })
              .flat();
            await form.validateFields(nameLists);

            // Execute handlers defined in the configuration file.
            if (handlers && handlers[actionType] && handlers[actionType][buttonType]) {
              handlers[actionType][buttonType](form.getFieldsValue(true), { formListName });
            }

            updateFormLocalValues();
            updateInnerRefProperty('formsTouched', true);
          } catch (error) {
            return;
          }
        }
      }

      if (actionType === actionTypes.UPDATE) {
        if (buttonType === 'dismiss') {
          // On DISMISS changes from UPDATE action.
          // Reset the fields values to what we have in local state.
          form.setFieldsValue(formLocalValues.current);
        }

        if (buttonType === 'confirm') {
          // On CONFIRM changes from UPDATE action.
          // If fields validation fails stop the execution of this function.
          // We don't want to set the default action as active action.
          try {
            const nameLists = filteredTableData
              .map((item, index) => {
                const listIndex = filteredIndexes[index + (current - 1) * pageSize];
                const nameList = getNameListForForm(formConfig, [...formListName, listIndex]);
                return nameList;
              })
              .flat();
            await form.validateFields(nameLists);

            // Execute handlers defined in the configuration file.
            if (handlers && handlers[actionType] && handlers[actionType][buttonType]) {
              handlers[actionType][buttonType](form.getFieldsValue(true), { formListName });
            }

            updateFormLocalValues();
            updateInnerRefProperty('formsTouched', true);
          } catch (error) {
            return;
          }
        }
      }
    }

    setActiveAction(defaultAction);
    updateFragmentProperty(formConfig.key, 'activeActionType', defaultAction.type);
  }

  // TODO: This should be handled in a side effect  to prevent overhead of calculations on each render.
  const formFields = getAllFieldsFromForm(formConfig);
  const columns = formFields.map((formField) => {
    const { formItem } = formField;
    return {
      key: formItem.name.join('.'),
      title: formItem.label && <FormattedMessage {...formItem.label} />,
      render: (text: string, record: FormTableDataItem<T>, index: number) => {
        const formListField = {
          name: filteredIndexes[index + (current - 1) * pageSize],
          key: filteredIndexes[index + (current - 1) * pageSize],
          fieldKey: filteredIndexes[index + (current - 1) * pageSize],
          isListField: true,
        };
        return (
          <FormField field={formField} formListField={formListField} removeFormItemLabel activeAction={activeAction} />
        );
      },
    };
  }) as ColumnsType<any>;

  if (itemActions && itemActions.length > 0) {
    columns.push({
      key: 'item-actions',
      title: <FormattedMessage id='actions' />,
      fixed: 'right',
      render: (text: string, record: FormTableDataItem<T>) => {
        return (
          <ItemActionsRoot>
            {
              // eslint-disable-next-line react/prop-types
              itemActions.map((itemAction) => (
                <EntryAction<T>
                  key={itemAction.type}
                  entry={record}
                  action={itemAction}
                  actionsDisabled={actionsDisabled}
                  uniqueIdProp={uniqueIdProp}
                  onClick={onClickItemAction}
                />
              ))
            }
          </ItemActionsRoot>
        );
      },
    });
  }

  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => {
        return (
          <>
            <Root>
              <CollapsibleCard
                panel={{
                  key: 'formTable',
                  header,
                  extra: (
                    <>
                      <Actions
                        actions={actions}
                        activeAction={activeAction}
                        listDropdownIsEmpty={filteredTableData.length === 0}
                        onClickAction={onClickAction}
                        onClickCreateCallback={add}
                        onClickDeleteCallback={remove}
                      />
                      <ActionButtons
                        action={activeAction}
                        lastFieldIndex={fields.length - 1}
                        onClickActionButton={onClickActionButton}
                        onClickDismissCallback={remove}
                      />
                    </>
                  ),
                }}
                ghost
                compact
                styles={{
                  header: { root: { minHeight: 'initial', padding: 0, fontSize: '22px' }, title: { padding: 0 } },
                }}
                defaultClosed={formBuilderProps.defaultClosed}
              >
                <Table
                  columns={columns}
                  dataSource={filteredTableData}
                  scroll={{ x: true }}
                  expandable={expandable}
                  pagination={{ current, pageSize, onChange: handleOnChangeCurrentPage }}
                />
              </CollapsibleCard>
            </Root>
            {tableListOperationsEvents && (
              <ListOperationsEvents
                screenFormConfigKey={screenFormConfigKey}
                eventHandlers={tableListOperationsEvents}
                add={add}
                remove={remove}
              />
            )}
          </>
        );
      }}
    </Form.List>
  );
}

export default FormTable;

const Root = styled.div`
  margin: 16px 0;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .ant-table-cell {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

const ItemActionsRoot = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;
